
import "./Onboarding.css"



export function Onboarding(props) {



    return (
        <div className="onboarding">
            <div className="content">
    
                <div className="textstyle">
                    <section>
                        <h4>Утраченная Колокольня Благовещенского собора</h4>
                    </section>
                </div>
                <button
                    className={`startbut ${props.isActive ? 'active' : 'inactive'}`}
                    disabled={props.isActive}
                    onClick={props.startCallback}>Начать</button>
                <div className="textstyletwo">
                    <section>
                        <h6>Подождите если кнопка не активна</h6>
                    </section></div>

                </div>

        </div>
        )
}

export default Onboarding;