import React, { useEffect, useRef } from 'react';
import './AudioGuide.css';
import Audio_guide from './Audio_guide.mp3';
import restart_icon from './restart_icon.png';
import pause_icon from './pause_icon.png';
import play_icon from './play_icon.png';

const AudioGuide = ({ playAudioGuide, onAudioPlay, onAudioPause }) => {
    const audioRef = useRef(null);

    useEffect(() => {
        if (playAudioGuide) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
    }, [playAudioGuide]);

    const handleRestartAudio = () => {
        audioRef.current.currentTime = 0;
        audioRef.current.play();
        onAudioPlay();
    };

    const handlePlayPauseAudio = () => {
        if (audioRef.current.paused) {
            audioRef.current.play();
            onAudioPlay();
        } else {
            audioRef.current.pause();
            onAudioPause();
        }
    };

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden && audioRef.current) {
                audioRef.current.pause();
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    return (
        <div>
            <audio ref={audioRef} src={Audio_guide} />
            <button
                onClick={handlePlayPauseAudio}
                className={`big-button ${playAudioGuide ? 'playing' : 'paused'}`}
            >
                {playAudioGuide ? <img src={pause_icon} alt="icon" className="button-icon" /> : <img src={play_icon} alt="icon" className="button-icon" />}
            </button>
            <button
                onClick={handleRestartAudio}
                className="small-button"
            >
                <img src={restart_icon} alt="icon" className="button-icon" />
            </button>
        </div>
    );
};

export default AudioGuide;