// PhotoModal.js
import React from 'react';
import './HisWidget.css'; // Make sure to style the modal

const PhotoModal = ({ isOpen, onClose, photoUrl, photoUrlTwo}) => {
    if (!isOpen) return null;
    

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="history-widget" onClick={(e) => e.stopPropagation()}>
                <span className="close-button" onClick={onClose}>&times;</span>
                <img src={photoUrl} alt="Modal" />
                    <p className="first_img_description">Фото. 1894г. источник pastvu.com</p>
                <section className="text-section">
                    <p className="zaglavie">Колокольня Благовещенского собора
                    </p>
                    <p> Это замечательное сооружение было построено в 17 веке с благословения казанского митрополита Матфея и возвышалось над городом около трехсот лет.
                        Это пятиярусное каменное строение было высотой более 50 метров, с огромным колоколом на первом ярусе, самым тяжелым в старинной Казани, весом почти две с половиной тонны.
                        В 18 веке на колокольне были установлены часы, а ее верхний купол в старину имел форму шлема.
                        </p>
                        <p>
                        По своей высоте колокольня была немного ниже башни Сююмбике. Три яруса огневидных кокошников украшали вершину здания.
                        По словам историка Михаила Пине́гина, в этом здании сочетались татарские и древнерусские стили: нижние этажи были вдохновлены архитектурой Сююмбике, а верхние напоминали колокольню Ивана Великого.
                        </p>
                        <p>
                        Колокольня прилегала к трапезной части собора, а под ней находился храм, изначально освященный в честь святой мученицы Ирины.
                        В XIX веке этот храм был восстановлен.
                        </p>
                        <p>
                        В 1928 году колокольню разрушили, теперь на ее месте находится сквер к югу от собора.
                        </p>
                    </section>
                <img src={photoUrlTwo} alt="Modal" />
                <p className="second_img_description">Вид с колокольни, фото. 1904г. источник pastvu.com</p>
            </div>
        </div>
    );
};

export default PhotoModal;