import React, { useEffect, useRef } from 'react';
import "./VideoBroadcast.css"


const VideoBroadcast = ({ cameraPermissionGranted }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (cameraPermissionGranted) {
            startBroadcast();
        }
    }, [cameraPermissionGranted]);

    const startBroadcast = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                videoRef.current.play();
            }
        } catch (error) {
            console.error('Error starting video broadcast:', error);
        }
    };

    return (
        <div>
            <video
                ref={videoRef}
                playsInline={true}
                className="cameraBack"
            />
        </div>
    );
};

export default VideoBroadcast;