import PilotAudio from './Start_audio.mp3'; // Import the image
import React, { useEffect, useRef } from 'react';

const AudioPlayer = ({ playAudio }) => {
    const audioRef = useRef(null);

    useEffect(() => {
        if (playAudio) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
    }, [playAudio]);

    return (
        <audio ref={audioRef} src={PilotAudio} />
    );
};

export default AudioPlayer;