import React, { useEffect, useState } from 'react';
import { isMobile } from "../../../../utils/utils";

const GyroscopeWidget = ({ onRotationSceneChange }) => {
    const [orientationData, setOrientationData] = useState({
        alpha: null,
        beta: null,
        gamma: null,
    });

    useEffect(() => {
        const handleOrientation = (event) => {
            const alpha = event.alpha;

            setOrientationData({
                alpha,
                beta: event.beta,
                gamma: event.gamma,
            });

            // Check if the device is Android
            //const isAndroid = /Android/i.test(navigator.userAgent);

            // If the device is Android and alpha is between 70 and 110
                if (isMobile.Android() && alpha >= 70 && alpha <= 110) {
                onRotationSceneChange(true);
            } else {
                // console
            }
        };

        if (window.DeviceOrientationEvent) {
            window.addEventListener('deviceorientation', handleOrientation);
        } else {
            console.error("DeviceOrientationEvent is not supported");
        }

        return () => {
            window.removeEventListener('deviceorientation', handleOrientation);
        };
    }, [onRotationSceneChange]);

};

export default GyroscopeWidget;