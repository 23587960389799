import "./StartButtons.css"
import React from 'react';
import { isMobile } from "../../../../utils/utils";



const StartButtons = ({ onOrientationPermissionResult, onCameraPermissionResult }) => {
    const requestOrientationPermission = async () => {
        if (isMobile.Android()) {
            onOrientationPermissionResult(true);
}
        else {
            if (typeof DeviceOrientationEvent !== 'undefined' && typeof DeviceOrientationEvent.requestPermission === 'function') {
                try {
                    const permission = await DeviceOrientationEvent.requestPermission();
                    if (permission === 'granted') {
                        onOrientationPermissionResult(true);
                    } else {
                        onOrientationPermissionResult(false);
                    }
                } catch (error) {
                    console.error('Error requesting device orientation permission:', error);
                    onOrientationPermissionResult(false);
                }
            } else {
                console.warn('DeviceOrientationEvent.requestPermission is not supported on this device.');
                onOrientationPermissionResult(false);
            }
        }
}
        ;

    const requestCameraPermission = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
            if (stream) {
                onCameraPermissionResult(true);
                // Stop all video tracks to release the camera
                //stream.getTracks().forEach(track => track.stop());
            }
        } catch (error) {
            console.error('Error requesting camera permission:', error);
            onCameraPermissionResult(false);
        }
    };

    const handleButtonClick = async () => {
        await requestOrientationPermission();
        await requestCameraPermission();
    };

    return (<div>
        <section className="startbuttons">
            
            {/* Content */}
            <div className="content">
                <div className="textstyle">
                    <section>
                        <h4>Для запуска виртуальной истории нам нужен доступ к некоторым сенсорам вашего устройства</h4>
                    </section>
                </div>
                <button className="permissionbutton" onClick={handleButtonClick}>Разрешить</button>
            </div>

        </section>
    </div>
    );
};

export default StartButtons;