import { UnityContainer } from "./components/UnityContainer/UnityContainer";


function KremlinStoryPage() {

    return (
        <div>
            <UnityContainer
            />
        </div>
    );
}

export default KremlinStoryPage;
