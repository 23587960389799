// PhotoModal.js
import React from 'react';
import './PhotoModal.css'; // Make sure to style the modal

const PhotoModal = ({ isOpen, onClose, photoUrl }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <span className="close-button" onClick={onClose}>&times;</span>
                <img src={photoUrl} alt="Modal" />
            </div>
        </div>
    );
};

export default PhotoModal;