import "./Onboarding.css"
import rotatingImage from './fogBack.png'; // Import the image
export function StartBackground() {


    return (
        <div className="background">
            {/* Background Images */}
            <div className="spottop-left"></div>
            <img
                src={rotatingImage}
                alt="Background Rotating Small"
                className="background-image small"
            />
            <img
                src={rotatingImage}
                alt="Background Rotating Large"
                className="background-image large"
            />
            <img
                src={rotatingImage}
                alt="Background Rotating Large"
                className="background-image-extralarge"
            />
            <img
                src={rotatingImage}
                alt="Background Rotating Large"
                className="background-image-backlarge"
            />
            <img
                src={rotatingImage}
                alt="Background Rotating Large"
                className="background-image-toplarge"
            />
            <div className="backcontent">
                <section>
                    <h6> </h6>
                </section>
            </div>
        </div>)
}

export default StartBackground;