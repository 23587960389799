import React from 'react';
import './HomePage.css';

import CompanySection from './components/CompanySection';
import Skills from './components/Skills';
import AboutUs from './components/AboutUs';
import Portfolio from './components/Portfolio';
import Testimonial from './components/Testimonials';
import ContactMe from './components/ContacntMe';
import Footer from './components/Footer';
import Navbar from './components/Navbar';


function HomePage() {
  

    return (
        <>
        <Navbar />
            <CompanySection />
            <Skills />
            <AboutUs />
            <Portfolio />
            <Testimonial />
            <ContactMe />
            <Footer />
        </>

    );
}

export default HomePage;